import { useTranslation } from 'react-i18next';
import React from 'react';
import { Typography, Box } from '@mui/material';

export const HardwareRequirementContent: React.FC = () => {
  const { t } = useTranslation();
  const hardware = [
    {
      key: 'java',
      subtitle: t('overview.hardware.java.subtitle'),
      description: t('overview.hardware.java.description'),
    },
    {
      key: 'memory',
      subtitle: t('overview.hardware.memory.subtitle'),
      description: t('overview.hardware.memory.description'),
    },
    {
      key: 'disk',
      subtitle: t('overview.hardware.disk.subtitle'),
      description: t('overview.hardware.disk.description'),
    },
    {
      key: 'processingPower',
      subtitle: t('overview.hardware.processingPower.subtitle'),
      description: t('overview.hardware.processingPower.description'),
    },
  ];

  return (
    <>
      {hardware.map((requirementItem) => (
        <Box
          key={`${requirementItem.key}-boundingBox`}
          sx={{ ':nth-of-type(1)': { mt: 0 }, mt: 2 }}
        >
          <Typography
            key={`${requirementItem.key}-subtitle`}
            variant="body1"
            sx={{ fontWeight: 'bold' }}
          >
            {requirementItem.subtitle}
          </Typography>

          {requirementItem.description.split(/[\r\n]+/).map((line, lineIdx) => (
            <Typography key={`${requirementItem.key}-description-${lineIdx}`}>
              {line}
            </Typography>
          ))}
        </Box>
      ))}
    </>
  );
};
