import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import DownloadMaltego from '@/components/home/DownloadMaltego';
import Documentation from '@/components/home/Documentation';
import { HeadPortal } from '@maltego/ui';
import { FileType } from '@/components/home/AllVersions';

const Home: React.FC<{ downloadJson }> = (props) => {
  const { t } = useTranslation();

  const mappedData = useMemo(() => {
    const initData = {
      linux: [],
      mac: [],
      windows: [],
    };

    return props.downloadJson.reduce((acc, downloadFile) => {
      const fileData: FileType = {
        fileTitle: downloadFile['file-type'],
        md5Hash: downloadFile['hash_md5'],
        sha256Hash: downloadFile['hash_sha256'],
        downloadLink: downloadFile['url'],
      };

      if (acc[downloadFile.os]) {
        acc[downloadFile.os].push(fileData);
      } else {
        acc[downloadFile.os] = [fileData];
      }

      return acc;
    }, initData);
  }, [props.downloadJson]);

  return (
    <>
      <HeadPortal title={t('overview.pageTitle')} />
      <div>
        <Typography variant="h1" sx={{ mb: 2 }}>
          {t('overview.pageTitle')}
        </Typography>
        <DownloadMaltego downloadJson={mappedData} />
        <Box height={52} />
        <Documentation />
      </div>
    </>
  );
};

export async function getServerSideProps() {
  try {
    const res = await fetch(process.env.NEXT_PUBLIC_DOWNLOAD_JSON_URL);
    const downloadJson = await res.json();
    return { props: { downloadJson } };
  } catch (e) {
    console.error(e);
    return { props: {} };
  }
}

export default Home;
