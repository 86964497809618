import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Grid, Paper, Typography } from '@mui/material';
import OperatingSystemTabs from '@/components/home/OperatingSystemTabs';
import OverviewAccordion, {
  AccordionLink,
} from '@/components/home/OverviewAccordion';
import { HardwareRequirementContent } from '@/components/home/HardwareRequirementContent';
import { VersionFiles } from '@/components/home/AllVersions';

const DownloadMaltego: React.FC<{ downloadJson: VersionFiles }> = ({
  downloadJson,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={0}>
      <Grid item xs={9} md={8}>
        <Paper
          elevation={0}
          sx={{
            pt: 12,
            px: 2,
            background: 'linear-gradient(180deg, #FAFAFA 0%, #ffffff 100%)',
            borderTopLeftRadius: 28,
          }}
        >
          <Typography
            sx={{
              fontSize: 48,
              fontWeight: 300,
              letterSpacing: '-0.00638em',
              lineHeight: 1,
              mb: 3.5,
              pr: 6,
            }}
          >
            {t('overview.heading1')}
          </Typography>

          <OperatingSystemTabs downloadJson={downloadJson} />
          <Typography variant="h3" sx={{ textTransform: 'none', mt: 5, mb: 2 }}>
            {t('overview.requirements')}
          </Typography>
          <OverviewAccordion summary={t('overview.hardware.summary')}>
            <HardwareRequirementContent />
            <AccordionLink
              link={
                'https://docs.maltego.com/support/solutions/articles/15000008703-client-requirements'
              }
              linktext={t('overview.fullRequirements')}
            />
          </OverviewAccordion>
          <OverviewAccordion summary={t('overview.network.summary')}>
            <Typography>{t('overview.network.description')}</Typography>
            <AccordionLink
              link={
                'https://docs.maltego.com/support/solutions/articles/15000008703-client-requirements'
              }
              linktext={t('overview.fullRequirements')}
            />
          </OverviewAccordion>
          <Box sx={{ height: '30px' }} />
          <Typography variant="h3" sx={{ textTransform: 'none', my: 2 }}>
            {t('overview.faq')}
          </Typography>
          <OverviewAccordion
            summary={t('overview.requiredJavaVersion.summary')}
          >
            <Trans>{t('overview.requiredJavaVersion.description')}</Trans>
            <AccordionLink
              link={'https://adoptium.net/temurin/releases/?version=17'}
            />
            <Typography>
              {t('overview.requiredJavaVersion.description2')}
            </Typography>
            <AccordionLink
              link={
                'https://docs.maltego.com/support/solutions/articles/15000011960-which-version-of-java-should-i-use-and-how-do-i-configure-it-'
              }
            />
          </OverviewAccordion>
        </Paper>
      </Grid>
      <Grid item xs={3} md={4}>
        <Box
          sx={(theme) => ({
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
            background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, rgba(193, 203, 207, 0.426042) 45.83%, #FAFAFA 100%)`,
          })}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 10,
              left: 10,
              height: 50,
              width: 100,
              backgroundImage:
                "url('/images/logos/Maltego-Horizontal-White.svg')",
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 10,
              left: -120,
              height: 510,
              width: 525,
              backgroundImage: "url('/images/backgrounds/overview-hex.svg')",
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 6,
              left: 30,
              height: 320,
              width: 454,
              backgroundImage:
                "url('/images/backgrounds/overview-right-side-image.png')",
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default DownloadMaltego;
