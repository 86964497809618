import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import {
  InPageError,
  MAlert,
  MTabs,
  MTextField,
  useToggle,
} from '@maltego/mui-core';
import OperatingSystemEnum from '@/components/home/OperatingSystemEnum';
import { FileType, VersionFiles } from '@/components/home/AllVersions';
import SecurityCheckModal from '@/components/home/SecurityCheckModal';
import DownloadIcon from '@/icons/overview-download.svg';
import ShieldIcon from '@/icons/overview-shield.svg';
import { t } from 'i18next';

const DownloadPanel: React.FC<{
  availableFileItems: FileType[];
}> = ({ availableFileItems }) => {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useToggle();

  const [selectedDownloadFile, setSelectedDownloadFile] = useState<FileType>(
    availableFileItems[0]
  );
  const currentFileTitle = selectedDownloadFile?.fileTitle;

  const handleFileTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const index = availableFileItems.findIndex(
      (element) => element.fileTitle === value
    );
    setSelectedDownloadFile(availableFileItems[index]);
  };

  if (availableFileItems.length === 0) {
    return (
      <Box
        sx={{
          '.MuiButton-root': {
            display: 'none',
          },
        }}
      >
        <MAlert severity="info">
          {t('components.OperatingSystemTabs.noDownloadFile')}
        </MAlert>
      </Box>
    );
  }

  return (
    <>
      <MTextField
        select
        value={currentFileTitle}
        onChange={handleFileTitleChange}
        label={t('overview.textfieldLabel')}
        sx={{ width: 270 }}
      >
        {availableFileItems.map((item, index) => (
          <MenuItem key={index} value={item.fileTitle}>
            {item.fileTitle}
          </MenuItem>
        ))}
      </MTextField>
      <Box display="flex" sx={{ mt: 0.5, width: 270 }}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          startIcon={
            <SvgIcon
              sx={{ fill: 'none' }}
              component={DownloadIcon}
              viewBox="0 0 20 20"
            />
          }
          href={selectedDownloadFile.downloadLink}
          rel="noreferrer noopener"
        >
          {t('common.button.download')}
        </Button>
        <Tooltip title={t('overview.securityCheck')} placement="top" arrow>
          <IconButton
            data-testid="shieldButton"
            disableRipple
            sx={(theme) => ({
              p: 0.75,
              ml: 0.5,
              borderRadius: 0,
              backgroundColor: 'grey.100',
              '&:hover': {
                backgroundColor: 'primary.main',
                '.MuiSvgIcon-root': {
                  fill: theme.palette.background.default,
                },
              },
              transition: `background-color ${theme.transitions.duration.shorter}ms`,
            })}
            onClick={onOpen}
          >
            <SvgIcon
              sx={{
                fill: 'primary.main',
              }}
              component={ShieldIcon}
              viewBox="0 0 16 16"
            />
          </IconButton>
        </Tooltip>
        <SecurityCheckModal
          open={isOpen}
          onClose={onClose}
          selectedDownloadFile={selectedDownloadFile}
        />
      </Box>
    </>
  );
};

const tabList = (downloadJson: VersionFiles) => [
  {
    label: 'Windows',
    Component: <DownloadPanel availableFileItems={downloadJson.windows} />,
  },
  {
    label: 'Linux',
    Component: <DownloadPanel availableFileItems={downloadJson.linux} />,
  },
  {
    label: 'Mac',
    Component: <DownloadPanel availableFileItems={downloadJson.mac} />,
  },
];

const getDefaultOperatingSystem = () => {
  let userOSName = OperatingSystemEnum.Windows;
  if (navigator.userAgent.toLowerCase().indexOf('win') !== -1) {
    userOSName = OperatingSystemEnum.Windows;
  } else if (navigator.userAgent.toLowerCase().indexOf('linux') !== -1) {
    userOSName = OperatingSystemEnum.Linux;
  } else if (navigator.userAgent.toLowerCase().indexOf('mac') !== -1) {
    userOSName = OperatingSystemEnum.Mac;
  }
  return userOSName;
};

const OperatingSystemTabs: React.FC<{ downloadJson: VersionFiles }> = ({
  downloadJson,
}) => {
  const tabListMemo = useMemo(() => {
    if (downloadJson) {
      return tabList(downloadJson);
    }
  }, [downloadJson]);

  if (!downloadJson) {
    return (
      <InPageError message={t('error.inPageError.unableToFetchInstallers')} />
    );
  }

  return (
    <MTabs
      initialTab={getDefaultOperatingSystem()}
      tabList={tabListMemo}
      testId="os-options-tabs"
    />
  );
};

export default OperatingSystemTabs;
