import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  IconButton,
  Link,
  Paper,
  SvgIcon,
  Typography,
} from '@mui/material';
import DocumentationProps from '@/components/home/DocumentationProps';
import { AtSign, ExternalLinkIcon } from '@maltego/ui';

export const DOCUMENTATIONS = (t): DocumentationProps[] => [
  {
    id: 'productDocumentation',
    description: t('overview.documentations.documentation'),
    redirectLink: 'https://docs.maltego.com/support/home',
    imgPath: '/images/backgrounds/overview-documentation-bg.svg',
    linkIcon: ExternalLinkIcon,
  },
  {
    id: 'support',
    description: t('overview.documentations.support'),
    redirectLink: 'mailto:support@maltego.com',
    imgPath: '/images/backgrounds/beta-support-bg.svg',
    linkIcon: AtSign,
    viewBox: '0 0 16 16',
  },
];

const Documentation = () => {
  const { t } = useTranslation();
  const documentationList = useMemo(() => DOCUMENTATIONS(t), [t]);

  return (
    <Grid container spacing={1} sx={{ pl: 2 }}>
      {documentationList.map((item) => (
        <Grid key={item.id} item xs={12} sm={6}>
          <Link
            target="_blank"
            underline="none"
            href={item.redirectLink}
            rel="noreferrer noopener"
          >
            <Paper
              elevation={0}
              sx={{
                position: 'relative',
                height: 193,
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'grey.50',
                backgroundImage: `url(${item.imgPath})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                '&:hover': {
                  backgroundImage: `url(${item.imgPath}), url('/images/icons/noise.png')`,
                  backgroundRepeat: 'no-repeat, repeat',
                },
                '&:hover .MuiTypography-root': {
                  color: '#852720',
                },
                '&:hover .MuiButtonBase-root': {
                  backgroundColor: 'primary.main',
                },
                '&:hover .MuiSvgIcon-root': {
                  color: 'white',
                },
                transition: (theme) =>
                  `color, transform ${theme.transitions.duration.shorter}ms`,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 40,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h3" sx={{ textTransform: 'none', mb: 2 }}>
                  {t(item.description)}
                </Typography>
                <IconButton
                  sx={(theme) => ({
                    backgroundColor: 'secondary.main',
                    p: 1.5,
                    transition: `background-color ${theme.transitions.duration.shorter}ms`,
                  })}
                >
                  <SvgIcon
                    sx={(theme) => ({
                      fill: 'none',
                      color: 'primary.main',
                      fontSize: 30,
                      transition: `fill ${theme.transitions.duration.shorter}ms`,
                      mt: 1,
                      ml: 1.25,
                      pb: 1,
                      pr: 1,
                    })}
                    // Account for different SVG export config
                    viewBox={item.viewBox || '0 0 24 24'}
                    component={item.linkIcon}
                  />
                </IconButton>
              </Box>
            </Paper>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default Documentation;
